
// acknowledge and alarm processing history
.acknowledge {
  width: 35vw;

  .row-1 {
    h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .input-row {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .box {
    border: 0.75px solid #696C6F;
    border-radius: 8px;
    background-color: #F6F6F7;
    height: 16vh;
    max-height: 155px;
    padding: 10px;
    overflow-y: auto;
  }
  .box-1 {
    border: 0.75px solid #696C6F;
    border-radius: 8px;
    height: 30vh;
    max-height: 255px;
    padding: 10px;
    overflow-y: auto;
  }
}