.add-position {
    width: 50vw;

    .add-position-form {
        padding: 10px;
    }

    .add-position-buttons {
        padding: 10px;
    }
}