.time-input-field {
  position: relative;
  display: flex;
  width: 100%;

  .input_field {
    border: none;
    width: 65px;
    padding: 5px 0 5px 5px;

    &:focus,
    &:active,
    &:valid {
      outline: none;
      border: none;
    }
  }
}