.add-library {
    width: 50vw;

    .add-library-form {
        padding: 10px;
    }

    .add-attribute-buttons {
        padding: 10px;
    }
}