.search-device {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 80vw;
  // height: 74vw;
  // max-height: 70%;
  overflow-y: auto;

  .title {
    .download-link {
      text-decoration: underline;
      color: $input-label-color;
      cursor: pointer;
      padding: 0 0 0 5px;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // align-items: center;
    column-gap: 24px;

    .input-row {
      display: flex;
      gap: 20px;
    }

    .search-wrapper {
      padding-left: 20px;
      display: flex;
      align-items: center;
      gap: 15px;

      .button-field .btn {
        padding: 5px 30px;

        &:disabled {
          opacity: unset;
          cursor: not-allowed;
          background-color: $primary-color;
          color: $white-color;
        }
      }

      .search-loader {
        display: flex;
        gap: 15px;
        align-items: center;

        .count {
          font-size: 14px;
          color: $primary-color;
          font-weight: 500;
        }
      }

      .search-result {
        span {
          font-size: 14px;
          color: #3E4246;
        }
      }

    }
  }

  .device-table {
    max-height: 430px;
    // overflow-y: auto;

    .table {
      box-shadow: none;

      thead tr {
        background-color: $background-color;
      }

      tbody {
        background-color: transparent;
      }
    }
  }

  .device-bottom {
    display: flex;
    flex-direction: column;

    .error-message {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #E8212E;
    }
  }
}

.search-add-link {
  text-decoration: underline;
  color: $primary-color;
  cursor: pointer;
}

.search-add-device {
  width: 50vw;
  // display: flex;
  // flex-direction: column;
  // gap: 30px;

  .add-device-form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .single-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
    
    // .single-row-1 {
    //   display: grid;
    //   column-gap: 20px;
    //   grid-template-columns: 32% 1fr;
    // }

    .error-label {
      display: flex;
      gap: 8px;
      color: #E8212E;
    }
  }
}