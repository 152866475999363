.add-level-modal .row-device {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .selected-devices {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 25px;
    }
}