.forgot-password-maincontainer {
    display: flex;
    justify-content: center;

    .forgot-password-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 80vh;
        background-color: $light-color;
        width: 30vw;
        margin-top: 60px;

        h2 {
            font-size: 1.5rem;
            font-weight: 600;
            text-color: $text-color;
            margin-top: 20px;
        }

        p {
            font-size: 1.2rem;
            text-color: $text-color;
            margin-top: 40px;
            padding-right: 60%;
        }

        form {
            width: 320px;
            background-color: $white-color;
            padding: 2rem;
            color: $black-color;
            border-radius: 10px;
            margin-top: 15%;

            .email-input {
                width: 100%;
                margin-bottom: 20px;

                input {
                    width: 100%;
                    padding: 10px;
                    background-color: $background-color;
                    border-radius: 5px;
                    font-size: 1rem;
                    text-color: $text-color;

                }
            }

            .error-message {
                color: $danger-color;
                font-size: 0.9rem;
                margin-bottom: 15px;
            }

            .btn-primary {
                width: 100%;
                padding: 12px;
                background-color: $primary-color;
                font-size: 1rem;
                font-weight: 600;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:disabled {
                    background-color: $input-label-color;
                    cursor: not-allowed;
                }

                &:hover:not(:disabled) {
                    background-color: $primary-color;
                }
            }

            .back-to-login {
                text-align: center;
                margin-top: 15px;

                a {
                    font-size: 0.9rem;
                    background-color: $primary-color;

                    text-decoration: none;
                    transition: color 0.2s;

                    &:hover {
                        background-color: $primary-color;

                    }
                }
            }
        }

        .back-to-login {
            padding-top: 25px;
        }
    }
}