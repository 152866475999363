.form-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .input_field {
        position: relative;
        flex: 1;
        background: transparent;
        font-size: 14px;
        letter-spacing: 1.2px;
        border: solid 1px $border-color;
        border-radius: 8px;
        transition: all .3s ease;
        padding: 13px 0px 13px 10px;

        &:focus {
            border-color: $primary-color;
        }

        &:read-only+.input-label {
            font-size: 13px;
            top: -6px;
            letter-spacing: 1px;
            background: $white-color;
            padding: 0 6px;
        }

        &:read-only {
            background-color: $read-only;
        }

        &:focus+.input-label,
        &:not(:placeholder-shown)+.input-label,
        &:valid+.input-label {
            font-size: 13px;
            top: -5px;
            letter-spacing: 1px;
            background: $white-color;
            color: $primary-color;
            padding: 0 6px;
        }

        &:focus+.input-label {
            color: $primary-color;

            span {
                color: $danger-color !important;
            }
        }

        &:focus,
        &:active,
        &:valid {
            outline-color: $primary-color;
            border-color: $primary-color;
        }

        &:disabled,
        &:disabled+.input-label,
        &:disabled+.input-label+.input-right-icon {
            cursor: not-allowed;
            opacity: 0.6;
            border: none;
            color: $black-color;
        }

        &:disabled+.input-label {
            background: transparent;
        }

        &:-webkit-autofill {
            background-color: #f4f7fc !important;
            -webkit-box-shadow: 0 0 0px 1000px #f4f7fc inset !important;
            transition: background-color 5000s ease-in-out 0s;

            &::first-line {
                background-color: #f4f7fc !important;
                transition: background-color 5000s ease-in-out 0s;
            }
        }

        &.error {
            border: $danger-color solid 1px;

            &+.input-label {
                color: $danger-color;
            }
        }
    }

    .input-label {
        position: absolute;
        cursor: text;
        z-index: 2;
        top: 13px;
        letter-spacing: 1.5px;
        left: 10px;
        font-size: 14px;
        white-space: 2;
        color: $input-label-color;
        transition: all .3s ease;

        &.ar {
            right: 10px
        }

        span {
            color: $danger-color !important;
        }
    }

    .input-right-icon {
        position: absolute;
        top: 13px;
        right: 10px;
        cursor: pointer;

        &.ar {
            right: 90%;
        }
    }
    
    .input-right-icon-2 {
        position: absolute;
        top: 13px;
        right: 35px;
        cursor: pointer;
        
        &.ar {
            right: 90%;
        }
    }

    .input-error-message {
        color: $danger-color;
        // margin-bottom: 20px;
    }

    .input-validation-message {
        color: $input-label-color;
        font-size: 14px;
        margin-top: 5px;
    }

    .input-success-message {
        color: $success-color;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input[type=password] {
    font-weight: bold;
    font-size: 14px;
}