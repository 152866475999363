.export-photo {
  width: 57vw;

  .flex-row {
    .col {
      width: 25%;
    }

    .col-2 {
      width: 30%;
    }
  }
}

// image preview
.photo-path-preview {
  width: 50vw;

  .img-wrapper {
    max-width: 453px;
    max-height: 451px;
  }

  .preview-image {
    width: 100%;
    height: 100%;
  }
}