// add all mixins this component
@mixin transition($property: all, $duration: 0.1s, $ease: ease-in-out) {
  transition: $property $duration $ease;
}

@mixin hover-effect($color: $primary-color, $decoration: none) {
  &:hover {
    color: $color;
    text-decoration: $decoration;
  }
}

@mixin header-title() {
  color: #292D31;
  font-size: 15px;
  font-weight: 500;
}

@mixin box($height: 180px, $max-height: ($height + 5)) {
  height: $height;
  max-height: $max-height;
  border: 1px solid $border-color;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  overflow-y: auto;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}