.alarm-monitoring {

  .grid-container {
    display: flex;
    gap: 10px;

    .flex-box {
      display: flex;
      flex: 0 0 250px;
      flex-direction: column;
      gap: 10px;
    }

    .chart-box {
      .card .card-body {
        padding: 15px;

        .chart-box-wrapper {
          display: flex;
          flex-direction: column;

          .legends {
            display: grid;
            row-gap: 10px;
            grid-template-columns: 1fr 1fr;

            .each-legends {
              display: flex;
              align-items: center;

              .badge {
                width: 10px;
                height: 10px;
                border-radius: 3px;
                margin-right: 15px;
              }

              .text {
                font-size: 12px;
                font-weight: bold;
              }
            }

          }
        }
      }
    }

    .alarm-events {
      .alarm-events-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0 10px 20px 10px;

        .rows {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .label {
            font-size: 14px;
            color: #696C6F;
          }

          .value {
            font-size: 24px;
            color: #292D31;
            font-weight: 400;
          }
        }
      }
    }

    .action-buttons {
      .card .card-body {
        padding: 0;
      }

      .button-group {
        display: flex;
        justify-content: space-between;
        padding: 15px;

        .btn {
          &.btn-outline-primary {
            &:hover {
              background-color: unset;
              color: $primary-color;
            }
          }

          svg {
            path {
              stroke: currentColor;

              &:hover {
                stroke: currentColor;
              }
            }
          }
        }
      }
    }

    .record-table {
      width: 77.5%;

      .card {
        height: 100%;
      }
    }
  }
}