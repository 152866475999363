.rtm-door {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .search-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-input {
      width: 23%;
    }
  }

  .card-container {
    height: 290px;
    max-height: 548px;
    padding-right: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 5px;

    .card-grid {
      // display: grid;
      // grid-template-columns: repeat(6, 1fr);
      // grid-template-rows: 90px;
      // gap: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .card-item {
        width: 155px;
        height: 66px;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 6.3px 0px #00000024;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding: 0 8px;

        &.active {
          border: 2px solid $primary-color;
        }

        &:hover .card-item-status,
        &:hover .card-item-checkbox,
        &:hover .card-item-edit {
          visibility: visible;
          opacity: 1;
        }

        &:last-child {
          &:hover .card-item-status {
            top: 72%;
            left: -100px;
            transition: all 0.1s ease;
            // left: 80%;
            // top: 20px;
            // left: 0px;
            // right: -10px;
            // transform: translateX(-50%);
          }
        }

        .card-item-checkbox {
          position: absolute;
          visibility: hidden;
          top: 5px;
          right: 0px;
          z-index: 2;

          &.active {
            visibility: visible;
            opacity: 1;

          }
        }

        .card-item-edit {
          position: absolute;
          visibility: hidden;
          top: 5px;
          right: 35px;
          z-index: 2;
          opacity: 0;
        }

        .card-item-status {
          // display: none;
          position: absolute;
          opacity: 0;
          visibility: hidden;
          top: 72%;
          left: 80%;
          z-index: 2;
          background-color: $white-color;
          transition: all 0.3s ease-in-out;

          .card-status {
            width: 270px;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 0px 14.1px 2px #0000001C;
            font-size: 12px;
            gap: 5px;
          }
        }

        span {
          padding-left: 5px;
          font-size: 14px;
        }
      }
    }
  }

  .status-container {
    display: flex;
    justify-content: end;

    .status-wrap {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }

      .circle-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.success {
          background-color: $success-text-color;
        }

        &.danger {
          background-color: $danger-color-2;
        }

        &.warning {
          background-color: $warning-badge-text;
        }

        &.secondary {
          background-color: $border-color;
        }
      }
    }
  }
}