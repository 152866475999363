.drop-down {
    position: relative;

    .drop-down-label {}

    .drop-down-container {
        position: absolute;
        height: auto;
        max-height: 300px;
        z-index: 9;
        display: none;
        background-color: $white-color;
        min-width: 100px;
        box-shadow: 2px 4px 10px $shadow-color;
        border-radius: 6px;

        &.active {
            display: block;
        }

        &.right::before {
            content: ' ';
            position: absolute;
            transform: rotate(133deg);
            width: 10px;
            top: -5px;
            right: 10px;
            height: 10px;
            background-color: $white-color;
            z-index: -1;
        }

        &.left::before {
            content: ' ';
            position: absolute;
            transform: rotate(133deg);
            width: 10px;
            top: -5px;
            left: 10px;
            height: 10px;
            background-color: $white-color;
            z-index: -1;
        }

        .drop-down-menus {
            display: flex;
            flex-direction: column;

            .menu {
                padding: 10px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                gap: 10px;

                &:hover {
                    background-color: $background;
                }

                &.disabled {
                    color: $text-disabled;
                    cursor: default;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }

        &.right {
            right: 0;
        }


        &.left {
            left: 0;
        }
    }

}