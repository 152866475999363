.add-wiegand-format {
    width: 70vw;
    padding: 15px;

    .add-wiegand-format-form {
        padding: 10px 0px;

        .row-form {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .col {
                flex: 1;
            }

            .col-0 {
                width: 30%;
            }

            .row-2 {
                width: 60%;
            }
        }

        .row-form-radio {
            padding: 10px 0px 20px 0px;
        }

        .flex-warp {
            padding: 10px 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px 50px;

            .warp-item {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 5px;

                .bit-value {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                }

                .input-error-message {
                    color: $danger-color;
                }
            }
        }
    }
}