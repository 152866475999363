.card {
    background-color: $white-color;
    border-radius: 8px;
    box-shadow: 2px 4px 10px $shadow-color;
    width: 100%;

    .sub-header {
        display: flex;
        justify-content: flex-start;
        padding: 20px 0px 0px 15px;
    }

    .card-header {
        padding: 15px 10px 10px 15px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .card-title {
            // font-size: 15px;
            font-weight: 500;
            color: #292D31;
        }
    }

    .card-body {
        padding: 10px 0px 0px 8px;
    }
}