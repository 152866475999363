.level-add-door {
  padding: 20px;
  // background-color: $white-color;
  border-radius: $box-border-radius;
  width: 65vw;
  margin: auto;
  // box-shadow: 0 4px 12px $shadow-color;

  // .table-container {
  //   display: flex;
  //   gap: 20px;

  //   .table-wrapper {
  //     flex: 1;
  //     max-height: 300px;
  //     overflow-y: auto;

  //     .title {
  //       font-size: 18px;
  //       font-weight: 600;
  //       margin-bottom: 10px;
  //     }

  //     table {
  //       width: 100%;
  //       border-collapse: collapse;

  //       th {
  //         text-align: left;
  //         padding: 10px;
  //         background-color: $background-color;
  //         font-weight: 500;
  //         font-size: 14px;
  //       }

  //       td {
  //         padding: 10px;
  //         border-bottom: 1px solid $border-color;
  //         font-size: 14px;
  //         text-align: left;
  //       }

  //       tr:hover {
  //         background-color: $light-color;
  //       }

  //       input[type='checkbox'] {
  //         margin-right: 10px;
  //       }
  //     }
  //   }

  //   .buttons {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 10px;

  //     button {
  //       padding: 10px 15px;
  //       background-color: $primary-color;
  //       color: $white-color;
  //       border: none;
  //       border-radius: 4px;
  //       font-size: 16px;
  //       cursor: pointer;
  //       transition: background-color 0.3s;

  //       &:hover {
  //         background-color: darken($primary-color, 10%);
  //       }

  //       &:disabled {
  //         background-color: $solid-color;
  //         cursor: not-allowed;
  //       }
  //     }
  //   }
  // }

  .form-buttons-right {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;

    .btn-default {
      background-color: $background-color;
      color: $text-color;
      padding: 10px 20px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: lighten($background-color, 10%);
      }
    }

    .btn-primary {
      background-color: $primary-color;
      color: $white-color;
      padding: 10px 20px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}
