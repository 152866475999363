.import-data {
    width: 35vw;
    height: 100%;
    position: relative;

    .row-1 {
        grid-template-columns: 80%;
        padding: 20px;
    }

    .add-department-buttons {
        bottom: 0;
        position: absolute;
        width: 100%;
    }
}