.add-global-antiPassBack {
  width: 35vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px 5px 0 5px;

  .form-row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .controlZone {
      gap: 10px;
    }

    .form-select-item .input_field:disabled {
      border: solid 1px $border-color;
    }
  }
}