.backup-Immediate {
  width: 50vw;
  padding: 0px 15px;

  .title {
    font-size: 14px;
    color: $read-only-disabled;
  }

  .checkbox-form-item .checkbox-group-list .checkbox-field .checkbox-title {
    color: $input-label-color;
  }

  .text-danger {
    margin-top: 20px;
  }
}