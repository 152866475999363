@font-face {
  font-family: Urbanist;
  src: url(../../assets/fonts/Urbanist/Urbanist-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Urbanist;
  src: url(../../assets/fonts/Urbanist/Urbanist-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Urbanist;
  src: url(../../assets/fonts/Urbanist/Urbanist-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/fonts/Poppins/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/fonts/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  font-family: Urbanist;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

/* use viewport-relative units to cover page fully */
body {
  height: 100vh;
  width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.App {
  height: 100%;
}

::-webkit-scrollbar {
  width: 3px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}