.access-by-level {

  // height: 100%;
  .action-wrapper {
    display: flex;
    justify-content: end;
    padding: 0 20px 10px 0;

    .action-btn {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .label {
        font-size: 12px;
        color: $text-color;
      }
    }
  }

  .line {
    margin: 30px 0;
    border: 1px solid $input-disabled;
  }

  .browse-table {
    .header {
      display: flex;
      flex-direction: column;
      gap: 20px;
      color: $read-only-disabled;
      padding: 15px 10px;

      .title {
        // font-size: 14px;
        font-weight: 500;
      }

      .sub-title {
        font-weight: 500;
      }
    }
  }
}

// access by level export data 

.export-data {
  // width: max-content;
  // max-width: 700px;

  .row.row-2 {
    grid-template-columns: max-content 49%;
  }
}

// add personnel

.access-rule-add-personnel {
  width: 68vw;

  .select-input {
    width: 40%;

    .text-danger {
      margin-top: 10px;
    }
  }
}