.replace-device {
  width: 38vw;
  padding: 10px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .row-input {
      width: 55%;
    }

    .warning-message-wrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
      // margin-bottom: 20px;

      .warning-msg {
        display: flex;
        align-items: center;

        .text-warning {
          margin-left: 10px;
        }
      }
    }
  }

  .form-buttons-right {
    margin-top: 30px;
  }
}