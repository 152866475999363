.view-device-capacity {
  width: 75vw;

  .tbl-container {

    height: 150px;

    .table {
      text-align: center;

      tbody {
        color: #696C6F;
      }

      .col-span {
        display: flex;
        justify-content: space-around;

        hr {
          margin-top: -16px
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: end;
  }
}