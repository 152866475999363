.tab-modal {
    display: none;
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    &.show {
        display: flex;
    }

    .tab-modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        min-width: 30%;
        border: 1px solid #888;
        border-radius: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;

        display: flex;

        .tab-modal-left {
            background-color: $background;
            padding: 5px 0px 5px 5px;

            .tab-title {
                padding: 5px 8px;
                font-weight: 600;
                font-size: 18px;
                color: $primary-color;
            }

            .tab-list-items {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;

                .tab-item {
                    font-size: 15px;
                    padding: 10px;
                    border-radius: 10px 0px 0px 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: $white-color;
                        color: $primary-color;
                    }

                    &.active {
                        color: $primary-color;
                        background-color: $white-color;
                    }
                }
            }
        }

        .tab-modal-right {
            min-width: 400px;
            min-height: 400px;
            display: flex;
            flex-direction: column;

            .modal-header {
                padding: 10px 16px;
                color: $primary-color;
                background-color: transparent;

                display: flex;
                justify-content: space-between;

                .modal-title {
                    font-size: 17px;
                    font-weight: 600;
                }

                .close {
                    cursor: pointer;
                }
            }

            .modal-body {
                flex: 1;
                padding: 10px;
            }
        }
    }
}

.css-1atu56z-MuiPaper-root {
    box-shadow: none !important;
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}