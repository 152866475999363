.switch-button {
  .switch {
      position: relative;
      display: inline-block;
      width: 55px;
      height: 23px;

      input {
          opacity: 0;
          width: 0;
          height: 0;
      }

      .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: .4s;
          border-radius: 34px;

          &:before {
              position: absolute;
              content: "";
              height: 17px;
              width: 17px;
              left: 2px;
              bottom: 3px;
              background-color: white;
              transition: .4s;
              border-radius: 50%;
          }
      }

      input:checked+.slider {
          background-color: #2196F3;
      }

      input:checked+.slider:before {
          transform: translateX(26px);
      }

      &.round {
          .slider {
              border-radius: 34px;

              &:before {
                  border-radius: 50%;
              }
          }
      }
  }
}