.permissions {
    .permissions-form {
        .row-form {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            gap: 20px;

            &.items-2 {
                grid-template-columns: auto auto;
                gap: 40px;
            }

            .item {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &.item-row {
                    flex-direction: row;
                    align-items: center;
                }

                .text-label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }

                .function-icon {
                    cursor: pointer;
                }
            }

            .item1 {
                grid-area: 1 / 1 / span 4 / span 1;

                .tree-view-dropdown {
                    width: 100%;
                    padding: 8px;
                    border: $border-color solid 1px;
                    border-radius: 8px;
                    height: 200px;
                    overflow-y: scroll;
                }
            }

            .item3 {
                grid-area: 1 / 3 / span 2 / span 1;
            }
        }
    }

    .level-settings-container {
        width: 238px;
        height: 226px;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        padding: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        .level-settings {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            .centered-add-button {
                border: none;

                &:hover {}

                &::after {
                    content: '+';
                    margin-left: 10px;
                }
            }

            &.hide-add-button {
                display: none;
                height: 0; // Ensures height is removed when hidden
            }
        }

        .level-settings-header {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            margin-bottom: 10px;

            .add-button {
                margin: auto 0;
            }
        }

        .level-settings-body {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 150px;
            overflow-y: auto;
            margin-top: 0;

            .checkbox-item {
                margin-bottom: 10px;
            }
        }

        .add-btn-wrapper {

            .add-button {
                background-color: #E0E0E0;
                border: none;
                border-radius: 4px;
                padding: 5px 10px;
                font-size: 12px;
                color: #333333;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: #D0D0D0;
                }

                // &::after {
                //     content: '+';
                //     margin-left: 5px;
                // }
            }
        }
    }

    .radio-label span {
        color: #ff1313;
    }
}