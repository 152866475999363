.view-rulesOf-devices {
  width: 898px;
  max-height: 771px;
  overflow: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .table {
    box-shadow: none;

    tbody tr td {
      padding: 10px 5px;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    .text-message {
      width: 65%;
      display: flex;
      align-items: self-start;
      gap: 6px;

      .text-warning {
        font-size: 14px;
      }
    }
  }
}