.add-attribute {
    width: 50vw;

    .add-attribute-form {
        padding: 10px;
        .row {
            .full-size {
                grid-column: 3;
                grid-row: 1 / span 4;
            }
        }
    }

    .add-attribute-buttons {
        padding: 10px;
    }
}

// Responsive style in sidebar
@media screen and (max-width: 1190px) {
    .add-attribute {
        .add-attribute-form {
            .row {
                .full-size {
                    grid-column: 1 / span 2;
                    grid-row: 4;
                }
            }
        }
    }
}

// Responsive style in sidebar
@media screen and (max-width: 720px) {
    .add-attribute {
        .add-attribute-form {
            .row {
                grid-template-columns: 100%;
                .full-size {
                    grid-column: 1 / span 1;
                    grid-row: 4;
                }
            }
        }
    }
}