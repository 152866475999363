.add-level-modal {
    padding: 20px;
    // background: $background-color;
    border-radius: 8px;
    width: 53vw !important;
    margin: auto;

    .form-row {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .row-add {
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        span {
            font-size: 14px;
            padding-top: 10px;
        }
    }

}