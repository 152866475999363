.intrusion {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .grid-row {
    display: grid;
    grid-template-columns: 33% 33%;
    column-gap: 20px;
  }

  .partition {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .header {
      display: flex;
      justify-content: space-between;

      .label {
        font-size: 14px;
      }

      .add-link {
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .box {
      @include box(150px, 150px);
    }
  }
}

// add partition
.add-partition {
  width: 66vw;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .table {
    border: 0.5px solid #D1D4D8;
    border-radius: 8px;
  }

  .table-container .table-wrapper .table tbody .no-data {
    min-height: 150px;
  }
}