.widget-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .widget-tabs {
        padding: 20px 10px;
        overflow: scroll;
        .tabs-list {
            display: flex;
            flex-direction: row;
            gap: 20%;
            .tab-items {
                display: flex;
                gap: 10px;
                align-items: center;
                position: relative;
                cursor: pointer;
                .number {
                    border: solid $border-color 1px;
                    border-radius: 50%;
                    text-align: center;
                    width: 19px;
                    height: 19px;

                    &.line1::after {
                        content: "";
                        position: absolute;
                        left: 18%;
                        top: 22px;
                        width: 22.2vw;
                        height: 1.5px;
                        background: $border-color;
                    }
                    &.done.line1::after {
                        background: $primary-color;
                    }
                    &.line2::after {
                        content: "";
                        position: absolute;
                        left: 14%;
                        top: 22px;
                        width: 23.6vw;
                        height: 1.5px;
                        background: $border-color;
                    }
                    &.done.line2::after {
                        background: $primary-color;
                    }
                }

                .label {
                    display: flex;
                    gap: 5px;
                    flex-direction: column;
                    .title {

                    }
                    .sub-title {

                    }
                }

                &.active {
                    .number {
                        background-color: $primary-color;
                        color: $white-color;
                        border-color: $primary-color;
                    }
                    .label {
                        .title {
                            color: $primary-color;
                        }
                        .sub-title {
                            color: $primary-color;
                        }
                    }           
                }
            }
        }
    }
    .page-content {
        padding: 10px;
    }
    .parameters-buttons {
        padding: 0px 10px 10px 0;
    }
}