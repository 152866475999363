.personal-details {
    .personal-details-form {
        .row-form-warp {
            display: grid;
            gap: 15px;
            grid-template-columns: auto auto auto auto;

            .column2 {
                grid-area: 1 / 4 / span 3 / span 2;
            }

            .flex-row {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
    }
}

.image-upload {
    height: 150px;
    background-color: $background-color;
    border-radius: 8px;

    .image-view {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
            height: 100%;
        }

        .close-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
    }

    &:hover .image-upload-session {
        display: flex;
    }

    .image-upload-session {
        position: relative;
        display: none;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .session-item {
            display: flex;
            cursor: pointer;
            flex-direction: column;
            gap: 5px;
            align-items: center;

            span {
                font-size: 12px;
                color: $shadow-color;
            }

            input {
                display: none;
            }
        }

        span {
            font-size: 11px;
            color: $shadow-color;

            &::before {
                content: "";
                position: absolute;
                left: 10px;
                top: 50%;
                width: 45%;
                height: 1.5px;
                background: $shadow-color;
            }

            &::after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                width: 45%;
                height: 1.5px;
                background: $shadow-color;
            }
        }
    }
}


// Responsive style code
@media screen and (max-width: 860px) {
    .personal-details {
        .personal-details-form {
            .row-form-warp {
                display: grid;
                gap: 15px;
                grid-template-columns: auto auto auto;

                .column2 {
                    grid-area: 4 / 1 / span 1 / span 3;
                }
            }
        }
    }
}

@media screen and (max-width: 670px) {
    .personal-details {
        .personal-details-form {
            .row-form-warp {
                display: grid;
                gap: 15px;
                grid-template-columns: auto auto;

                .column2 {
                    grid-area: 5 / 1 / span 2 / span 1;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .personal-details {
        .personal-details-form {
            .row-form-warp {
                display: grid;
                gap: 15px;
                grid-template-columns: auto;

                .column2 {
                    grid-area: 8 / 1;
                }
            }
        }
    }
}