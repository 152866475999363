.add-musterPoint {
  width: 60vw;
  padding: 10px;

  .form-row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .grid-row {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;

      .check-box-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .schedule-row {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .notify-row {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .flex-row {
        gap: 30px;
      }
    }

    .box-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 88%;

      .header {
        display: flex;
        justify-content: space-between;

        .add-link {
          color: $primary-color;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .box {
        @include box(150px, 150px);

        .d-flex {
          display: flex;
          gap: 8px;
          align-items: center;

          .remove-icon {
            cursor: pointer;
          }
        }
      }
    }
  }
}

/*************************add sign in point**********************/
.add-sign-point {
  width: 56vw;
}  