.door-modals {
  .label {
    font-family: Poppins;
    color: $primary-color;
    font-size: 13px;
    font-weight: 500;

    span {
      color: #212B36;
      font-weight: 400;
    }
  }

  .input-wrap {
    width: 45%;
  }

  .message {
    color: #292D31;
  }
}

// latest-events-popup
.latest-events-popup {
  .table-wrapper {
    width: 75vw;
    overflow-x: auto;
    max-height: 28vh;
    height: 25vh;
    overflow-y: auto;
    // scrollbar-width: thin;
    // scrollbar-color: #aaa #f1f1f1;

    &::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #696C6F;
      /* Color of the scrollbar thumb */
      border-radius: 10px;
      /* Rounded corners */
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      /* Background of the scrollbar track */
    }

    .tbl-container {
      overflow-x: auto;
    }

    .table {
      box-shadow: none;

      thead tr td {
        max-width: unset;
        white-space: nowrap;
        overflow: unset;
        text-overflow: unset;
      }
    }
  }
}