.notification {
    display: flex;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.active {
        visibility: visible;
        transform: translateY(100px);
        opacity: 1;
    }

    .notification-content {
        position: relative;
        background-color: #fefefe;
        min-width: 20%;
        max-width: 550px;
        // top: 100px;
        padding: 10px;
        border: 1px solid #888;
        border-radius: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        display: flex;
        gap: 10px;
        align-items: center;

        &.danger {
            border-color: $danger-color;
            background: $danger-light-color;
            color: $danger-color;
        }

        &.success {
            border-color: $success-color;
            background: $success-light-color;
            color: $success-color;
        }

        &.warning {
            border-color: $warning-color;
            background: $warning-light-color;
            color: $warning-color;
        }

        .notification-message {
            flex: 1;
        }

        .notification-close {
            cursor: pointer;
        }
    }
}