// commonly used the below modules:
// remote-opening, bg-verification-option, device timezone,
// get transaction, registration-device

.device-setup-popup {
  width: 38vw;
  padding: 10px;

  .row {
    // display: grid;
    // grid-template-rows: 1fr auto;
    display: flex;
    flex-direction: column;

    .column-1 {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .checkbox-wrapper {
        display: flex;
        gap: 10px;

        .button-field .btn {
          text-wrap: nowrap;
          padding: 5px 15px;
        }

      }

      .title {
        font-size: 14px;
      }

      .box {
        padding: 10px;
        height: 150px;
        max-height: 150px;
        overflow: auto;
        border: 0.75px solid $input-label-color;
        border-radius: $box-border-radius;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .message {
          font-size: 14px;
          font-weight: 500;
        }

        .text-warning {
          font-weight: 500;
          font-size: 14px;
        }

        .select-device-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;

          .active-name {
            font-size: 13px;
            font-weight: 500;
            color: $primary-color;
          }
        }

        &.box-1 {
          max-height: 20vh;
          overflow: scroll;
        }

        // success box text
        .success-wrapper {
          p {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }

      // get transaction
      .time-input {
        display: flex;
        gap: 10px;
        width: 75%;
      }

      // remote opening 
      .open-interval {
        display: flex;
        flex-direction: column;
        gap: 13px;
        padding-bottom: 20px;

        .seconds {
          font-size: 14px;
          text-wrap: nowrap;
        }
      }

      .verification-options {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
          font-weight: 500;
        }

        .input-row {
          display: flex;
          gap: 20px;

          .form-select-item .input_field {

            &:disabled {
              border: none;
              background-color: $input-disabled;
              // color: #696C6F;
            }

            &:read-only+.input-label {
              color: $read-only-disabled;
              background-color: transparent;
            }
          }
        }
      }

      .upgrading-content {
        display: flex;
        justify-content: space-between;

        .right {
          display: flex;
          align-items: center;
          gap: 5px;

          .search-loader {
            display: flex;
            gap: 15px;
            align-items: center;

            .count {
              font-size: 14px;
              color: $primary-color;
              font-weight: 500;
            }
          }
        }

        .button-field .btn {
          padding: 5px 15px;
        }
      }
    }

    .column-new {
      width: 60%;
    }
  }
}

// synchronize-prompt

.synchronize-prompt {
  text-align: left;

  .check-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}