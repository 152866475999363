.signin-manually {
  width: 43vw;
  padding: 10px;

  .form-row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .flex-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}