.delete-box {
    .message-text {
        padding: 40px;
        p {

        }
    }
    .confirm-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
        padding: 10px 0px 20px;
    }
}