.conformation-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 450px;
    text-align: center;

    .message-text p {
        font-size: 16px;
    }

    .confirm-buttons {
        display: flex;
        gap: 30px;
    }
}