.cleanup-button {
    display: flex;
    padding-top: 15px;
    .button-field .btn.btn-primary {
        color: $primary-color;
        background-color: $white-color;
        border: 1px solid $primary-color;
    }
    
}
.modal-body p {
    display: flex;
    align-items: center;
    justify-content: center;
}