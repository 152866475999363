.modify-communication-modal {
    background-color: $white-color;
    border-radius: $box-border-radius;
    // padding: 20px;
    width: 44vw;
    // margin: 0 auto;
  
    h3 {
      margin-bottom: 20px;
      color: $primary-color;
    }
  
    .form-row {
      display: flex;
      gap: 20px;
    }
  
    .form-error {
      color: $border-color;
      font-size: 0.85rem;
      margin-top: 10px;
    }
  
    .modal-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 68px;
    }
  }
  