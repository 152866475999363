@mixin responsive-width($width-px) {
  width: #{$width-px / 1920 * 100}vw;
}

.add-linkage {
  width: 62vw;
  max-height: 720px;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  .flex-row {
    .col {
      width: 24%;
    }
  }

  .select-device {
    display: flex;
    gap: 10px;

    .button-field .btn {
      // padding: 5px 10px;
      height: 10px;
    }

    .label {
      font-size: 14px;
      color: $input-label-color;
    }

    .value {
      font-size: 14px;
      font-weight: 600;
      color: $input-label-color;
      margin: 0;
    }
  }

  // output point
  .output-point {

    .form-select-item,
    .form-item {
      width: 17vw;
      margin-top: 10px;
    }
  }

  .linkage-box {
    .box-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .header {
        display: flex;
        justify-content: space-between;

        .add-link {
          color: $primary-color;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .box {
        @include box(185px, 185px);
      }
    }
  }
}

// linkage-trigger-connections
.linkage-trigger-connections {
  @include responsive-width(1200);
  display: flex;
  flex-direction: column;
  // gap: 20px;

  .table-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .table-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .table {
        box-shadow: none;
      }

      .table thead tr td {
        padding: 10px 15px;
        max-width: fit-content;
      }

      .table tbody tr td {
        padding: 10px 15px;
      }

      .table-title {
        @include header-title();
      }
    }
  }
}