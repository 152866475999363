.table-empty-content {
  width: 100%;
  height: 100%;
  justify-items: center;
  align-content: center;

  .content-wrapper {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .text {
      font-weight: 500;
      font-size: 14px;
      color: $input-label-color;
    }
  }
}