.transaction-types {
  width: 85%;

  .input-row {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 40%;
  }

  .new-transaction {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .btn-wrapper {
      display: flex;
      gap: 10px;
      justify-content: end;

      .button-field .btn {
        padding: 4px 10px;
      }
    }

    .checkbox-form-item {
      .checkbox-group-list {
        margin: 0 auto;
        gap: 20px 25px;

        .checkbox-field {
          width: 65px;
        }
      }
    }

    .flex-row {
      gap: 10px;
    }
  }
}

// Auto exports
.auto-exports {
  width: 85%;

  .form-box-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 43.5%;

    .time-wrapper {
      width: 38%;
    }

    .text-danger {
      margin-top: 10px;

      .error-msg {
        font-size: 13px;
      }
    }

    .by-month {
      display: flex;
      align-items: center;
      gap: 10px;

      .input-box {
        width: 25%;
      }
    }
  }
}

@media screen and (max-width: 710px) {
  .auto-exports {
    width: 100%;

    .form-box-row {
      width: 100%;
    }
  }
}