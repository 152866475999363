.card-status {
  width: 100%;
  border: none;
  border: 0.25px solid $border-color-2;
  border-radius: $box-border-radius;
  overflow: hidden;
  font-size: 12px;
  cursor: pointer;

  &.selected {
    border: 1px solid $primary-color;
  }

  .card-title {
    padding: 10px;
    background-color: $background;
    color: $read-only-disabled;
    font-size: 14px;
    font-weight: 500;
  }

  .card-body {
    padding: 5px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .content-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: $text-color;

      .badge {
        width: max-content;
        padding: 1px 5px;
        border-radius: 3px;
        &.Online {
          background-color: $background;
          color: $success-text-color;
        }
        &.Offline {
          background-color: $danger-light-bg;
          color: $danger-color-2;
        }
        &.Unknown {
          background-color: $border-color;
          color: $white-color;
        }
        &.Disable {
          background-color: $warning-badge-bg;
          color: $warning-badge-text;
        }
      }
    }

  }
  
  .card-footer {
    padding: 4px 10px 10px 10px;
    span {
      color: $primary-color;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
    }
  }
}