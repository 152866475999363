.card-right-button-group {
    display: flex;
    gap: 20px;
    align-items: center;
    
    .button-group {
        .view-button {
            cursor: pointer;
        }
        .hide-button {
            .button-list {
                display: flex;
                flex-direction: row;
                gap: 15px;
                .button-item {
                    cursor: pointer;
                    vertical-align: top;
                    font-size: 13px;
                }
            }
        }
    }
    .button-field {
        .btn.btn-sm {
            border-radius: 6px;
        }
    }
}