.device-selection-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white-color;
    padding: 20px;
    box-shadow: 0 4px 8px rgba($black-color, 0.2);
    z-index: 1000;
    border-radius: 8px;
    width: 800px;
    height: 500px;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h3 {
            margin: 0;
            font-size: 18px;
            color: $text-color;
        }

        .btn-close {
            background: none;
            border: none;
            font-size: 18px;
            cursor: pointer;
        }
    }

    .filters {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        gap: 50px;
        width: 60%;
        height: 40px;

        input[type="text"] {
            padding: 8px;
            border: 1px solid $solid-color;
            border-radius: 4px;
            font-size: 14px;
        }

        button {
            padding: 8px 12px;
            border: none;
            border-radius: 4px;
            background-color: $primary-color;
            color: $white-color;
            cursor: pointer;
            font-size: 14px;

            &:hover {
                background-color: darken($primary-color, 10%);
            }
        }

        .clear-button {
            background-color: $solid-color;

            &:hover {
                background-color: darken($solid-color, 10%);
            }
        }
    }

    .content {
        flex-grow: 1;
        overflow-y: auto;
    }

    .device-tables {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

        .available-devices,
        .selected-devices {
            width: 45%;

            table {
                width: 100%;

                th,
                td {
                    padding: 8px;
                    text-align: left;
                }

                th {
                    background-color: $background-color;
                    color: $text-color;
                }

                td {
                    font-size: 14px;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 10%;
            gap: 10px;
            padding-top: 150px;
        }
    }

    .form-buttons-right {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;
        padding-top: 20px;
    }
}
