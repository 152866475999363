.add-device {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 670px;

  .add-device-form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-item .input_field {
      flex: 0;
    }

    .grid-row {
      display: grid;
      grid-template-columns: 66% 1fr;
      column-gap: 10px;

      .col-8 {
        display: flex;
        flex-direction: column;
        gap: 33px;
      }
    }

    .single-row {
      display: grid;
      align-items: start;
      grid-template-columns: 170px 1fr;
      gap: 10px;

      .input-error-message {
        margin: 0;
      }

      .radio-form-item {
        .radio-group-list.column {
          flex-direction: row;
        }

        .radio-group-list .radio-field .radio-title {
          color: #696C6F;
        }
      }
    }

    .single-row-1 {
      display: grid;
      gap: 10px;
      grid-template-columns: 170px 1fr 1fr;
    }
  }

  .last-row {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;

  }

  .error-label {
    display: flex;
    gap: 8px;
    color: #E8212E;
    margin-top: 6%;
  }

  .button-field .btn {
    padding: 10px 35px;
  }
}

.search-add-links {
  display: flex;
  gap: 10px;
}