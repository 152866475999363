.modify-ip-address {
  padding: 10px;
  max-width: 600px;
  margin: 0 auto;
  // background-color: $background-color;
  border-radius: $box-border-radius;
  // box-shadow: 0px 2px 10px $shadow-color;

  h4 {
    color: $primary-color;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    input {
      width: 100%;
    }

    > div {
      flex: 0 0 48%;
      margin-bottom: 20px;
    }
  }

  input {
    border: 1px solid $border-color;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    color: $text-color;
    background-color: $white-color;

    &:disabled {
      background-color: $input-disabled;
      color: $text-disabled;
    }

  }
}
