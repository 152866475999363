.device-popup {
  width: 40vw;
  padding-bottom: 10px;

  .row {
    display: grid;
    grid-template-rows: 1fr auto;

    .column-1 {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .checkbox-wrapper {
        display: flex;
        gap: 10px;

        .button-field .btn {
          text-wrap: nowrap;
          padding: 5px 15px;
        }

        .checkbox-form-item .checkbox-group-list.warp.grid-2 .checkbox-field {
          width: 30%;

          .checkbox-title {
            color: $input-label-color;
          }
        }
      }

      .title {
        font-size: 14px;
      }

      .box {
        padding: 10px;
        height: 150px;
        max-height: 150px;
        overflow: auto;
        border: 0.75px solid $input-label-color;
        border-radius: $box-border-radius;
        display: flex;
        flex-direction: column;
        gap: 5px;

        // synchronize time
        .device-name {
          font-size: 15px;

          .message {
            font-size: 14px;
            font-weight: 400;
          }
        }

        //synchronize all data to devices
        .select-device-wrapper {
          display: flex;
          align-items: center;
          gap: 5px;

          .active-name {
            font-size: 13px;
            font-weight: 500;
            color: $primary-color;
          }
        }

        // success box text
        .success-wrapper {
          p {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }

        .text-warning {
          font-weight: 500;
          font-size: 12px;
        }

        &.box-1 {
          max-height: 20vh;
          overflow: scroll;
        }
      }

      .file-wrapper {
        .file-upload {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          .file-select {
            // font-size: 12px;
            color: $dark-black;
          }

          .brows-btn {
            padding: 5px;
            background-color: $background-color;
            border-radius: 6px;
            cursor: pointer;
          }

          .not-upload {
            color: $dark-black;
          }
        }
      }

      .upgrading-content {
        display: flex;
        justify-content: space-between;

        .right {
          display: flex;
          align-items: center;
          gap: 5px;

          .search-loader {
            display: flex;
            gap: 15px;
            align-items: center;

            .count {
              font-size: 14px;
              color: $primary-color;
              font-weight: 500;
            }
          }
        }

        .button-field .btn {
          padding: 5px 15px;
        }
      }
    }
  }
}