.modal {
    display: flex;
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    // &.show {
    //     display: flex;
    // }
    .modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        min-width: 30%;
        border: 1px solid #888;
        border-radius: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;

        .modal-header {
            padding: 10px 16px;
            color: $primary-color;
            font-size: 14px;

            display: flex;
            justify-content: space-between;

            .modal-title {
                font-size: 20px;
                font-weight: 600;
            }

            .close {
                cursor: pointer;
            }
        }

        .modal-body {
            padding: 10px;
        }
    }
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}