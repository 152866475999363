.radio-form-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .radio-label {
        span {
            color: $danger-color;
        }
    }
    .radio-group-list {
        display: flex;
        gap: 10px 20px;
        
        .radio-field {
            display: flex;
            flex-direction: row;
            gap: 10px;
            cursor: pointer;
            .radio-icon {
            }
            .radio-title {
                cursor: pointer;
                font-size: 14px;
            }
            &.right {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }

            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
                .radio-title {
                    cursor: not-allowed;
                }
            }
        }
        &.column {
            flex-direction: column;
        }
        &.warp {
            flex-wrap: wrap;
        }
        &.row-wrap {
            flex-wrap: wrap;
        }
    }
}