.acc-rule-parameters {
  .card .card-body {
    padding: 10px 30px 30px 10px;

  }
}

// real-time monitoring, alarm monitoring recipient, phone number

.parameters-inputs {
  width: 40%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .error-msg {
    font-size: 13px;
  }
}