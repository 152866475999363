.add-department {
    width: 50vw;

    .add-department-form {
        padding: 10px;
        .flex {
            display: flex;
            flex-wrap: wrap;
            gap: 10px 20px;

            .col {
                flex-grow: 1;
                height: auto;
                flex-basis: 250px;
            }
        }
    }

    .add-department-buttons {
        padding: 10px;
    }
}