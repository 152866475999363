.add-linkage-device {
  width: 66vw;

  .swap-table {
    .table-container {
      .table-wrapper {
        .tbl-container {
          min-height: 200px;
        }
      }
    }
  }
}