.tbl-container {
    height: 100%;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;

    &.bordered {
        border: 0.5px solid $light-text-color;
    }

    &::-webkit-scrollbar-track {
        border-radius: 8px;
        // background-color: #F5F5F5;
        margin: 5px 0px;

    }

    &::-webkit-scrollbar {
        width: 5px;
        // background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: $border-color;
    }

    .table {
        border-collapse: collapse;
        width: 100%;
        border-radius: 9px;
        box-shadow: 0px 1px 8px $shadow-color;

        thead tr {
            background: $shadow-color;
            box-shadow: 0 3px 12px $shadow-color;
        }

        thead tr td {
            border-left: solid 2px $white-color;
            position: sticky;
            position: -webkit-sticky;
            z-index: 8;
            top: 0;
            // background-color: $shadow-color;
            background-color: #F6F6F7;
        }

        thead tr td,
        tbody tr td {
            padding: 5px 5px;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.text-wrap {
                white-space: normal;
                overflow: visible;
                text-overflow: unset;
                max-width: 100%;
            }

            .checkbox-icon {
                cursor: pointer;
            }
        }

        tbody {
            .empty-data-wrapper {
                position: relative;
                left: 0;
                transform: translate(0, 0);
            }

            .no-data {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 10px;
                height: 150px;
                min-height: max-content;

                span {
                    color: $input-label-color;
                }
            }
        }
    }
}