.add-holidays {
  width: 49vw;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .grid-row {
    display: grid;
    grid-gap: 20px 30px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}