.access-control-setting {
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;

    .row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;

        .col {
            flex: 1;
            min-width: 200px;

            .radio-group,
            .checkbox-group {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;

                input[type="radio"],
                input[type="checkbox"] {
                    margin-right: 5px;
                }
            }

            &.time-fields {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 40px;
                padding-left: 39%;
                gap: 10px;

                input {
                    width: 83%;
                    border-radius: 7px;
                }
            }
        }
    }

}