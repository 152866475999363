.drag-image {

  .card-wrapper {
    position: absolute;
    top: 35px;
    left: 15px;
    width: 246px;
    z-index: 10;
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    cursor: default;

    .card-status {
      background-color: $white-color;
      height: 100%;
      cursor: default;

      .card-body {
        .content-row {
          .left {
            grid-area: auto;
          }
        }
      }
    }
  }
}

.drag-image:hover .card-wrapper {
  opacity: 1;
}