.backup-schedule {
  width: 50vw;
  padding: 0px 15px;

  .input-row {
    display: flex;
    gap: 20px;
    width: 80%;
  }
  
  .messages p {
    margin-bottom: 10px;
    color: $read-only-disabled;
  }

  .checkbox-form-item .checkbox-group-list .checkbox-field .checkbox-title {
    color: $input-label-color;
  }

  .text-danger {
    margin-top: 20px;
  }
}