.add-level-modal{
.select-device-wrapper{
    padding: 20px 0 20px 0;
  .device-name {
    text-align: center;
    border-radius: 8px;
    border: 1px solid $text-disabled;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 135px;
    padding: 14px 15px;

    .label {
        font-weight: 400;
    }
}
  }
}