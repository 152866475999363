.bitch-issue-card {
  max-width: 60vw;
  padding: 0px 7px;

  .bitch-issue-card-form {
    display: flex;
    flex-direction: column;

    .row-form {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      .col {
        flex: 1;
      }

      .col-text {
        .link-tag {
          color: $primary-color;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      padding: 20px 0px;
      padding-top: 60px;
      gap: 10px;

      .col {
        flex: 1;
        overflow-y: auto;
        /* Adds scrollbar for table content */
        height: 300px;
        /* Set a fixed height for the table to trigger scroll */

        .header {
          font-size: 10px;
          color: $primary-color;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin: 10px 0;
          font-size: 14px;
          text-align: left;

          th {
            background-color: #f5f5f5;
            color: #000;
            font-weight: bold;
            padding: 12px;
            border-bottom: 1px solid #ddd;
          }

          tr {
            border-bottom: 1px solid #ddd;

            &:nth-child(even) {
              background-color: #f9f9f9;
            }

            &:hover {
              background-color: #f1f1f1;
              cursor: pointer;
            }
          }

          td {
            padding: 12px;
            border-right: 1px solid #ddd;

            &:last-child {
              border-right: none;
            }
          }
        }
      }

      // The separator between the tables
      .dhxlayout_sep {
        background-color: #ddd;
        width: 5px;
        cursor: col-resize;

        &.dhxlayout_sep_resize {
          width: 10px;
        }
      }

      // Styles for Current issue person table
      .col-issue-person {
        flex: 1;
        overflow-y: auto;
        /* Adds scrollbar for the Current issue person */
        height: 300px;
        /* Same fixed height for consistency */
      }
    }

    .form-buttons-right {
      button {
        padding: 8px 16px;
        font-size: 14px;
        margin: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &.btn-default {
          background-color: #f5f5f5;
          color: #000;
        }

        &.btn-primary {
          background-color: #007bff;
          color: #fff;

          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }

    .header {
      h1 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #007bff;
      }
    }
  }
}