.checkbox-tree-field {
    .checkbox-label {

    }
    .checkbox-tree-field-session {
        padding: 10px;
        border: $border-color solid 1px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        height: 200px;
        overflow-y: scroll;
        gap: 10px;
        .tree-session {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .title {
                display: flex;
                gap: 10px;
                align-items: center;
                cursor: pointer;
                span {

                }
            }
            .checkbox-group-list {
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .checkbox-field {
                    display: flex;
                    gap: 10px;
                    cursor: pointer;
                    .checkbox-icon {

                    }
                    .checkbox-title {

                    }
                }
            }
        }
    }
}