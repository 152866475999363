.file-upload {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      span {
        color: $danger-color;
      }
    }

    .file-input {
      width: 408px;
      height: 152px;
      border-radius: 8px;
      border: 0.25px solid #696C6F;
      text-align: center;
      display: grid;
      place-items: center;
      padding: 10px;

      &.view {
        display: block;
        text-align: start;
        place-items: start;
      }

      .badge-wrapper {
        display: flex;
        align-items: center;

        .badge {
          display: flex;
          gap: 10px;
          align-items: center;
          border-radius: 6px;
          background-color: #EEF5FD;
          padding: 10px;

          .file-name {
            font-size: 12px;
            color: #292D31;
            max-width: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .remove-icon {
          cursor: pointer;
        }
      }

      .file-text {
        color: #696C6F;
        font-size: 14px;

        .text-secondary {
          cursor: pointer;
          font-weight: 600;
          letter-spacing: 0.8px;
          text-decoration: underline;
        }
      }
    }
  }