.personnel-setting {
    .personnel-setting-form {
        max-width: 400px;

        .row {
            .col {
                display: flex;
                flex-direction: column;
                gap: 20px;
                .title {
                    font-size: 16px;
                    font-weight: bold;
                }
                .link-tag {
                    color: $primary-color;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .column {
            display: grid;
            grid-template-columns: auto auto;
            gap: 20px 10px;

            .item-1 {
                grid-area: 1 / 1 / span 1 / span 2
            }
            .item-2 {
                grid-area: 1 / 2 / span 2 / span 1
            }

            .text {
                cursor: pointer;
            }
            .qrcode {
                width: 100px;
            }
        }
        .row1--3 {
            grid-template-columns: 30% auto;
        }
    }
    .w50 {
        max-width: 50%;
    }
    .w100 {
        max-width: 100%;
    }
    .row-3 {
        align-items: center;
    }
}