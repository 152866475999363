.card-title-right{
    display: flex;
    gap: 20px;
    align-items: center;
}

.button-field .btn.btn-primarys{  
    background-color: transparent;
    color: $primary-color;
    border-color:$primary-color;
}