.swap-table {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .search-input {
    width: 40%;
  }

  .table-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 12px;
    overflow-x: auto;

    .table-wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;

      table {
        // height: 100%;
        box-shadow: none;
      }

      .table tbody .no-data {
        min-height: 100px;
      }

      .table thead tr td {
        padding: 10px 15px;
        max-width: fit-content;
      }

      .table tbody tr td {
        padding: 10px 15px;
      }

      .title {
        font-size: 14px;
        font-weight: 400;
        color: #292D31;
      }
    }
  }

  .icons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    text-align: center;

    .icon {
      background-color: $shadow-color;
      padding: 8px;
      cursor: pointer;
      @include transition;

      &:hover {
        background-color: #c0c1c2;
      }

      &.backward {
        transform: rotate(180deg);
      }


      &.disabled {
        background-color: $shadow-color;
        pointer-events: none;
      }
    }
  }
}