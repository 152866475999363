.edit-door {
    width: 70vw;

    .grid-row {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

  .switch-wrap {
    display: flex;
    justify-content: end;
    padding: 20px 0;
  }
}