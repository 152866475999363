.modal-page{
.statistics-page{
    display: grid;
    grid-template-columns: none;
    gap: 20px;
    align-items: center;

    .grid-rows{
        display: flex ;
        flex-direction: row;
        gap: 20px;
    }
}
}