.edit-eventType {
  width: 55vw;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .grid-row {
    display: grid;
    grid-gap: 20px 30px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

    .form-select-item .input_field:disabled {
      background-color: $input-disabled;
    }
  }

  .switch-wrap {
    .checkbox-form-item .checkbox-group-list .checkbox-field .checkbox-title {
      color: $input-label-color;
    }
  }
}

// set audio
.set-audio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30vw;
  height: 22vh;

  .flex-row {
    display: flex;
    gap: 40px;
    align-items: center;
    width: 25vw;

    .button-field .btn  {
      padding: 4px 15px;
    }
  }
}