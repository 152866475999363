.select-box {
  position: relative;
  cursor: pointer;
  transition: all 0.3s;

  // Dropdown styling for English (LTR)
  .dropdown {
    position: absolute;
    top: 30px; // Distance from the top
    right: 0; // Align to the right side of the select box
    z-index: 10;
    background-color: $white-color;
    box-shadow: 0px 1px 8px 0px $shadow-color-2;
    border-radius: 8px;
    font-size: 14px;
    color: $input-label-color;
    max-height: 300px; // Maximum height for the dropdown
    overflow-y: auto;
    display: none; // Hide by default
    opacity: 0; // Make it invisible
    pointer-events: none; // Disable click events when hidden
    transition: visibility 0.3s, opacity 0.3s;

    &.dropdown-visible {
      display: block; // Show the dropdown
      opacity: 1; // Make it visible
      pointer-events: auto; // Enable click events
    }

    .options {
      display: flex;
      gap: 8px;
      align-items: center;
      width: 220px;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      // &:hover {
      //   background-color: $hover-bg-color; // Add a hover effect for options
      //   color: $hover-text-color;
      // }

      &.selected {
        background-color: $primary-color; // Background for selected option
        color: $white-color; // Text color for selected option
      }
    }
  }

  // Dropdown styling for Arabic (RTL)
  .dropdown-arabic {
    position: absolute;
    top: 30px;
    left: 0; // Align to the left side of the select box
    z-index: 10;
    background-color: $white-color;
    box-shadow: 0px 1px 8px 0px $shadow-color-2;
    border-radius: 8px;
    font-size: 14px;
    color: $input-label-color;
    max-height: 300px; // Maximum height for the dropdown
    overflow-y: auto;
    display: none; // Hide by default
    opacity: 0; // Make it invisible
    pointer-events: none; // Disable click events when hidden
    transition: visibility 0.3s, opacity 0.3s;

    &.dropdown-visible {
      display: block; // Show the dropdown
      opacity: 1; // Make it visible
      pointer-events: auto; // Enable click events
      width: 154px;
    }

    .options {
      display: flex;
      gap: 8px;
      align-items: center;
      width: 220px;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      // &:hover {
      //   background-color: $hover-bg-color; // Add a hover effect for options
      //   color: $hover-text-color;
      // }

      &.selected {
        background-color: $primary-color; // Background for selected option
        color: $white-color; // Text color for selected option
      }
    }
  }
}
