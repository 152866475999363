.unconfirmed {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .tab-list-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
    .tab-item {
      cursor: pointer;
      padding: 0px 0px 2px 5px;
      transition: .3s all ease-in-out;
      
      &.active {
        color: $primary-color;
        transition: .3s all ease-in-out;
        border-bottom: $primary-color 1px solid;
      }
    }
  }

  .header-options {
    display: flex;
    justify-content: end;
    gap: 23px;
    padding: 0px 10px;

    .option {
      cursor: pointer;

      .label {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }

  .table-wrapper {
    padding: 0 15px 0 15px;
    .table {
      box-shadow: none;

      tbody tr td {
        padding: 10px 5px;
      }
    }
  }
}