.group-switch-button {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .group-label {
    font-size: 14px;
  }

  .group-box {
    background-color: #464A4E;
    // height: 80px;
    border-radius: 3px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .box-header {
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
    }

    .switch-buttons {
      display: flex;
      // gap: 5px;
      justify-content: space-around;

      .switch-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        .count-label {
          color: white;
        }

        .switch-button {
          .switch {
            position: relative;
            display: inline-block;
            width: 17px;
            height: 35px;

            .slider {
              background-color: #3D4144;

              &:before {
                position: absolute;
                content: "";
                height: 13px;
                width: 13px;
                // left: 0px;
                bottom: 3px;
                background-color: #FFFFFF;
                transition: .1s;
                border-radius: 50%;
              }
            }

            input:checked+.slider {
              background-color: #2196F3;
            }

            input:checked+.slider:before {
              transform: translateY(-17px);
            }
          }
        }
      }
    }
  }
}