.edit-reader {
  width: 37.4vw;
  margin: 0 auto;

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 35px;

      .form-item {
        height: 48.4px;
      }
    }

  }
}