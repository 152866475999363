.data-setting {
  &-form {
    display: flex;
    padding-right: 19%;
    gap: 10%;

    .column {
      flex: 1;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      position: relative;

      .text-danger.flex-row.center {
        position: absolute;
        left: 500px;
        top: 0;
        max-width: 200px;
        display: flex;
        align-items: flex-start;
        color: $danger-color;
        font-size: 14px;
      }
    }

    .record-title {
      font-size: 16px;
      font-weight: 500;
      color: $shade-color;
      margin-bottom: 8px;
      position: relative;

      &::after {
        content: '*';
        color: $danger-color;
        position: absolute;
        margin-left: 4px;
      }
    }

    .sub-title {
      font-size: 14px;
      color: $text-color;
      margin-bottom: 8px;
    }

    .main-container {
      display: flex;
      flex-direction: row;
      padding-bottom: 20px;
      gap: 20px;
    }

    .form-item {
      width: 24vw;
    }

    .note {
      padding-bottom: 20px;
    }
  }
}

