.row{
    display: grid;
    gap: 20px;
    &.row-1 {
        grid-template-columns: 100%;
    }
    &.row-2-1 {
        grid-template-columns: 49% 49%;

        .full-field {
            grid-area: 2 / 1 / span 2 / span 2;
        }
    }
    &.row-4 {
        grid-template-columns: 24% 24% 24% 24%;
    }
    &.row-3 {
        grid-template-columns: 32% 32% 32%;
    }
    &.row-2 {
        grid-template-columns: 49% 49%;
    }
}



// Responsive style in sidebar
@media screen and (max-width: 1190px) {
    .row{
        &.row-4 {
            grid-template-columns: 49.3% 49.3%;
        }
        &.row-3 {
            grid-template-columns: 49% 49%;
        }
    }
}
@media screen and (max-width: 710px) {
    .row{
        &.row-4 {
            grid-template-columns: 99%;
        }
        &.row-3 {
            grid-template-columns: 99%;
        }
        &.row-2-1 {
            grid-template-columns: 99%;

            .full-field {
                grid-area: 3 / 1 / span 1 / span 1;
            }
        }
    }
}