.parameters {
    .card {
        .card-header {
            padding: 20px 20px 0px 20px;
        }
        .card-body {
            padding: 10px;
        }
    }
    .parameters-buttons{
        padding: 0px 10px;
    }
}