.add-zone {
  width: 45vw;
  height: 200px;
  justify-content: space-between;

  .grid-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

/************************************************rules-apply************************/

.rules-apply {
  width: 45vw;
}