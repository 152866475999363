.add-daylight {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: $white-color;
  border-radius: $box-border-radius;
  width: 451px;
  max-width: 100%;
  margin: 0 auto;

  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 30px;

    label {
      font-size: 14px;
      color: $input-label-color;
      margin-bottom: 8px;
    }

    input,
    select {
      width: 100%;
      border: 1px solid $solid-color;
      border-radius: 4px;
      font-size: 14px;
    }
  }

  .form-start {
    display: flex;
    width: 412px;
    gap: 20px;
  }

  .modal-footer {
    display: flex;
    padding-top: 16px;

    .btn-primary {
      background-color: $primary-color;
      color: $white-color;
      padding: 10px 16px;
      border-radius: 4px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    .btn-default {
      background-color: $background;
      color: $text-color;
      border: 1px solid $solid-color;
      padding: 10px 16px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: $background;
      }
    }
  }
}