.level-body {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    max-height: 500px;
    width: 50vw;
    overflow: hidden;

    .input-fields {
        display: flex;
        margin-bottom: 20px;

        .search-container {
            position: relative;
            width: 40%;

            input.input-field {
                width: 100%;
                padding: 10px 10px 10px 40px; // Padding for internal spacing
                border: 1px solid #E0E0E0;
                border-radius: 4px;
                font-size: 14px;

                &:focus {
                    outline: none;
                    border-color: #007BFF;
                    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
                }
            }

            svg {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .levels-container {
        display: flex;
        justify-content: space-between;
        overflow-y: auto; // Allows scrolling within the container

        .levels-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
            display: block; // Display as block to handle scroll
            height: 300px; // Set a fixed height for the table to enable scrolling
            border: 1px solid #E0E0E0;
            border-radius: 8px;

            thead {
                display: block; // Make the thead block to ensure it's fixed
                position: sticky;
                top: 0;
                background-color: #f1f1f1;
                z-index: 1; // Ensure the header is above the tbody
            }

            tbody {
                display: block; // Make tbody block to handle scroll
                max-height: 250px; // Adjust based on available height within the table
                overflow-y: auto; // Enable vertical scrolling
            }

            th,
            td {
                padding: 10px;
                text-align: left;
                width: 200px; // Ensure consistent column width
            }

            th {
                background-color: #f1f1f1;
                font-weight: bold;
                font-size: 14px;
                color: #333;
            }

            tbody {
                tr:nth-child(even) {
                    background-color: #f9f9f9;
                }

                tr:hover {
                    background-color: #f1f1f1;
                }
            }
        }

        .available-levels,
        .selected-levels {
            width: 50%; // Evenly split the available and selected sections
            padding: 10px;
            overflow-y: auto; // Scroll bar for each table container
            max-height: 360px; // Maximum height before scrolling is needed
        }
    }
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 20px;
    gap: 20px;

    .cancel-button,
    .done-button {
        padding: 10px 20px;
        margin-left: 10px;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        transition: background-color 0.3s;

        &.cancel-button {
            background-color: #CED0D3;
        }

        &.done-button {
            background-color: #9EA3A9; // Default background color for disabled state
            color: white;

            &.active {
                background-color: #007bff; // Primary color for active state
            }

            &.disabled {
                background-color: #9EA3A9; // Background color for disabled state
                cursor: not-allowed;
            }
        }
    }
}