.add-device-modal {
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;

    h3 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 20px;
        color: $text-color;
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;

        >div {
            flex: 1;
            min-width: 200px;
        }
    }

    .name-device-protocol-row {
        display: flex;
        gap: 20px;
        // margin-bottom: 20px;

        .input-error-message {
            margin: 0;
        }

        >div {
            flex: 1;
            min-width: 150px;

            &:first-child {
                flex: 1;
            }
        }

        .device-name {
            text-align: center;
            border-radius: 8px;
            border: 1px solid #CED0D3;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 14px 15px;

            .label {
                font-weight: 400;
            }
        }

        .button-field .btn {
            padding: 10px 46px;
        }
    }

    .info-message {
        font-size: 0.9rem;
        color: $light-text-color;
        margin-bottom: 20px;

        p {
            color: $text-color;
            font-weight: 700;
        }

        img {
            max-width: 20%;
            height: auto;
            margin: 10px 0;
            border: 1px solid $border-color;
            border-radius: 4px;
        }

        .warning {
            color: $danger-color;
            font-weight: 600;
        }
    }

    .form-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        .btn-select-device {
            padding-right: 30px;
        }

    }

    input,
    select {
        width: 100%;
        padding: 8px;
        border: 1px solid $solid-color;
        border-radius: 4px;
        font-size: 1rem;
        color: $text-color;
        transition: border-color 0.3s;

        &:focus {
            border-color: $primary-color;
            outline: none;
        }
    }

    label {
        font-size: 0.85rem;
        margin-bottom: 5px;
        display: block;
        color: $input-label-color;
        font-weight: 500;
    }

    .group-switch-button {
        width: 23%;
        padding-bottom: 20px;
    }
}