.modal-page {
    min-width: 40vw;

    .statistics-page {
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 20px;
        align-items: center;

        .item {
            .card {
                .card-header {
                    .card-title {
                        font-weight: 500;

                        .title {}

                        .sub-title {
                            color: $light-text-color;
                        }
                    }
                }
            }

            .types-list {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .items {
                    display: flex;
                    justify-content: space-between;

                    .title {
                        font-size: 16px;
                    }

                    .value {
                        color: $border-color;

                        &.width-full {
                            flex: 1;
                        }

                        &.width-full-right {
                            flex: 1;
                            text-align: right;
                        }
                    }
                }
            }
        }

        .row {
            display: flex;
            flex-direction: row;

            .downloaddriver {
                .devWarningColor {
                    color: $primary-color;

                    a.devWarningColor {
                        color: $green2;
                        text-decoration: underline;
                    }
                }
            }
        }

        .row-2 {
            grid-column: span 4;
        }

        .span-2 {
            grid-column: span 2;
        }

        .span-4 {
            grid-column: span 4;
        }

        &.rows-2 {
            grid-template-columns: auto auto;
        }
    }
}

.card-images {
    display: flex;
    gap: 20px;

    .image {
        height: 30vh;

        img {
            width: 100%;
            height: 100%;
        }
    }

}

.finger-print-modal {
    width: 46vw;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .select-wrapper {
        .rectangle-wrap {
            display: flex;
            gap: 5px;

            .rectangle {
                width: 53px;
                height: 12px;
                background-color: $white2;
            }

        }
    }

    .fingers-img-wrapper {
        display: flex;
        gap: 10px;

        img {
            width: 155px;
            height: 172px;
        }
    }
}

.card-number-modal {
    width: 70vw;

    .card-type-wrapper {
        display: flex;
        justify-content: center;
    }
}