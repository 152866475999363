@mixin progress-bar-styles($background-color) {
  appearance: none;
  height: 10px;
  border-radius: 100px;

  /* Chrome and Safari */
  &::-webkit-progress-bar {
    background: $white-color;
    border-radius: 100px;
  }

  &::-webkit-progress-value {
    background-color: $background-color;
    border-radius: 100px;
  }

  /* Firefox */
  &::-moz-progress-bar {
    background-color: $background-color;
    border-radius: 100px;
  }
}

.muster-point-report {
  .card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .data-analysis-header {
      display: grid;
      grid-template-columns: 250px 100px;
      gap: 20px;


      .button-field .btn {
        border: none;
        color: $primary-color;
        font-weight: 500;
      }

      .button-field .btn:disabled {
        background-color: transparent;
      }
    }

    .box-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 16px;
      padding: 20px 10px 15px 10px;

      .box {
        width: 320px;
        height: 100px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 10px;

        &.box-1 {
          background-color: $success-green;
        }

        &.box-2 {
          background-color: $danger-brown-bg;
        }

        &.box-3 {
          background-color: $warning-light-bg;
        }

        .box-body {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icon-wrapper {
            padding-left: 10px;
          }

          .count {
            h4 {
              font-size: 20px;
              color: $read-only-disabled;
            }

            .title {
              margin-top: -3px;
              color: $border-color;
            }
          }

          .progress-bar {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .percentage {
              font-weight: 500;
              color: $read-only-disabled;
            }

            .safe-prog-bar {
              @include progress-bar-styles($green-shade);
            }

            .danger-prog-bar {
              @include progress-bar-styles($red-shade);
            }

            .total-prog-bar {
              @include progress-bar-styles($yellow-shade);
            }
          }
        }
      }
    }
  }
}