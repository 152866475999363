.table-menu {
    padding: 10px 13px;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:hover {
        background-color: $background-color;
    }
}

.MuiPaper-root {
    // box-shadow: inherit !important;
}

.table-header-right {
    display: flex;
    align-items: center;
    // gap: 20px;

    .refresh-button {
        padding: 7px;
        margin-right: 5px;
        margin-top: 5px;
        border-radius: 50%;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}