.interlock-modal {
  padding: 20px;
  border-radius: 8px;
  width: 53vw !important;
  margin: auto;

  .form-row {
      display: flex;
      flex-direction: row;
      gap: 20px;
  }

  .row-add {
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      span {
          font-size: 14px;
          padding-top: 10px;
      }
  }

  .select-device-wrapper{
    padding: 20px 0 20px 0;
  .device-name {
    text-align: center;
    border-radius: 8px;
    border: 1px solid $text-disabled;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 135px;   
    padding: 14px 15px;

    .label {
        font-weight: 400;
    }
}
  }
  .device-name.disabled {
    cursor: not-allowed !important;
    pointer-events: none !important;
    opacity: 0.6 !important;
    border: none !important;
    background-color: $read-only !important;
  }
}


  