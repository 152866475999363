.tab-page {
    padding: 10px;

    .tab-modal-content {
        .tab-list-items {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .tab-item {
                cursor: pointer;
                padding: 0px 0px 2px 0px;
                max-width: 178px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.active {
                    color: $primary-color;
                    border-bottom: $primary-color 1px solid;
                }
            }
        }

        .tab-body {
            padding: 20px 0px;

            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}