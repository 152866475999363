.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 20px;

  .header-left {
    .header-menus {
      position: relative;

      .header-main-menus {
        &:hover .menu-container {
          // display: block;
          // animation: top-animate .3s forwards;
          transform: translate(0, 5px);
          opacity: 1;
          visibility: visible;
        }

        .menu-icon {
          padding: 5px;
        }

        .menu-container {
          // display: none;
          visibility: hidden;
          position: absolute;
          z-index: 10;
          top: 30px;
          left: 5px;
          width: 564px;
          height: auto;
          max-height: 414px;
          overflow-y: auto;
          background-color: #FFFFFF;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1019607843);
          padding: 16px;
          border-radius: 8px;
          transition: all .5s ease;

          .menu-list {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .menu-card {
              width: 110px;
              height: 100px;
              padding: 16px;
              border-radius: 8px;
              background-color: #F6F6F7;
              display: flex;
              gap: 16px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: all .3s ease-in-out;

              .menu-title {
                color: #696C6F;
                font-size: 14px;
              }

              &:hover {
                scale: 1.03;
                background-color: #EEF5FD;
              }

              &.active {
                transition: all .2s ease-in-out;
                background-color: #078DEE;

                .menu-title {
                  color: $white-color;
                }
              }
            }
          }
        }

        &:not(:hover) .menu-container {
          opacity: 0;
          top: 20px; // Move back up when hiding
          transition: opacity 0.3s ease, top 0.3s ease;
        }
      }
    }
  }

  .header-right {
    .header-buttons-group {
      display: flex;
      gap: 20px;
      align-items: center;

      .language-button {}

      .profile-button {}
    }
  }
}

.profile-icon {
  width: 34px;
  height: 34px;
  background: $primary-color;
  color: $white-color;
  font-weight: 600;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

@keyframes top-animate {
  0% {
    top: -10px;
    opacity: 0;
  }

  100% {
    top: 30px;
    opacity: 1;
  }
}