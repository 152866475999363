.edit-reader-modal {
    background-color: $white-color;
    padding: 20px;
    border-radius: $box-border-radius;
    width: 100%;
    margin: 0 auto;

  .form-buttons-right {
    grid-column: span 3;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .btn-default,
    .btn-primary {
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 4px;
      font-weight: bold;
      cursor: pointer;
    }

    .btn-default {
      background-color: $light-color;
      color: $text-color;
      margin-right: 10px;
    }

    .btn-primary {
      background-color: $primary-color;
      color: $white-color;
    }
  }
}

.edit-reader-form {
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .input-grid {
    display: grid;
    gap: 40px;

    .input-form,
    .input-select {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  .input-item {
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
      margin-bottom: 5px;
      color: $text-color;
    }

    input,
    textarea,
    select {
      padding: 10px;
      font-size: 16px;
      border: 1px solid $solid-color;
      border-radius: 4px;
      background-color: $background-color;
    }

    textarea {
      resize: none;
      height: 60px;
      width: 190px;
    }
  }
}