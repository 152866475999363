.layout {
    padding: 0;
    margin: 0;
    height: 100%;
    .admin-panel {
        display: grid;
        grid-template-columns: 15% 85%;
        height: 100%;
        .sidebar {
            // width: 300px;
        }
        .right-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            .header {

            }
            .container {
                background-color: $background-color;
                flex: 1;
                padding: 10px 15px;
            }
        }
    }
}




// Responsive style in layout
@media screen and (max-width: 1490px) {
    .layout {
        .admin-panel {
            grid-template-columns: 60px 95%;
            .sidebar {
                
            }
        }
    }
}

@media screen and (max-width: 1190px) {
    .layout {
        .admin-panel {
            grid-template-columns: 60px 94%;
        }
    }
}

@media screen and (max-width: 500px) {
    .layout {
        .admin-panel {
            grid-template-columns: 100%;

            .sidebar {
                display: none;
            }
        }
    }
}