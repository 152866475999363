.card-format-testing {
    width: 60vw;

    .card-format-testing-form {
        padding: 10px 0px 20px 0px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .row-form {
            display: flex;
            gap: 10px;
            align-items: center;

            .flex-1 {
                flex: 1;
            }
        }

        .column-form {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .col {
                .title {
                    padding-bottom: 10px;
                }

                .rows {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 10px;

                    .org-card-no {
                        .label {
                            margin-top: -25px;
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}