.acknowledge-popup {
  width: 40vw;
  padding-bottom: 10px;

  .row {
    // display: grid;
    // grid-template-rows: 1fr auto;

    .column-1 {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        font-weight: 500;
        font-size: 14px;
      }

      .upgrading-content {
        display: flex;
        justify-content: space-between;
      }

      .box {
        padding: 10px;
        height: 150px;
        max-height: 150px;
        overflow: auto;
        border: 0.75px solid $input-label-color;
        border-radius: $box-border-radius;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}