.checkbox-form-item {
    display: flex;
    flex-direction: column;
    gap: 15px;


    .checkbox-label {
        span {
            color: $danger-color;
        }
    }

    .checkbox-group-list {
        display: flex;
        gap: 10px 20px;

        .checkbox-field {
            display: flex;
            flex-direction: row;
            gap: 10px;
            cursor: pointer;

            .checkbox-icon {}

            .checkbox-title {
                font-size: 14px;
                cursor: pointer;
            }

            &.right {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }

            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;

                .checkbox-title {
                    cursor: not-allowed;
                }
            }
        }

        &.column {
            flex-direction: column;
        }

        &.warp {
            flex-wrap: wrap;

            &.grid-2 {
                .checkbox-field {
                    width: 46%;
                }
            }
        }
    }
}