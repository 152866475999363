.add-verification-mode {
  width: 88vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 10px;
  overflow-y: auto;

  .form-row-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }

    .form-row-1 {
      display: flex;
      justify-content: space-between;
    }
  }


  .table {
    width: 100%;
    border-collapse: collapse;
    color: $read-only-disabled;
    font-size: 14px;

    thead {
      tr {
        th {
          background-color: $background-color;
          text-align: start;
          padding: 10px 0 5px 5px;
          font-weight: 500;
          border-left: solid 3px $white-color;
          border-bottom: solid 3px $white-color;
        }

        .date-time-header {
          width: 180px;
          white-space: nowrap;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 0 0px 5px;

          .form-item {
            .input_field {
              padding: 5px 0 5px 5px;

              &:focus,
              &:active,
              &:valid {
                outline: none;
                border: none;
              }
            }

            .input-label {
              display: none;
            }
          }
        }
      }

      .table-inputs {
        width: 120px;

        .form-select-item .input-label {
          width: 90px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .form-select-item .input_field {
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }
      }
    }
  }

}