.switch-network-modal {
    padding: 20px;
    background-color: $white-color;
    border-radius: $box-border-radius;
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  
    .form-row {
      margin-bottom: 20px;
  
      label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        color: $text-color;
      }
  
      .radio-group {
        display: flex;
        justify-content: flex-start;
        gap: 15px;
  
        label {
          font-size: 14px;
          font-weight: 400;
          color: $light-text-color;
        }
      }
    }
  }
  