.outgoing-mail-server-settings {
    padding: 20px;
    width: 500px;
  
    h3 {
        font-size: 16px;
      margin-bottom: 20px;
    }
  
    .flex-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
  
      input {
        width: 81%;
      }
      .checkbox-title {
        font-size: 14px;
        padding-right: 5px;
        cursor: pointer;
      }
    }
    .field-helper-text {
        font-size: 0.875rem;
        color: $ashade;
        margin-bottom: 15px;
      }

    .flex-input {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

       input {
          width: 81%;
        }
      }
  
    .checkbox-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
  
      label {
        margin-right: 10px;
      }
    }
  
    .single-row {
      margin-bottom: 20px;
  
      input {
        width: 100%;
      }
    }
  
      }
  