.view-personnel {
    .view-personnel-form {
        .row {
            .full-size {
                grid-column: 3;
                grid-row: 1 / span 4;
            }
        }
    }
}

// Responsive style in sidebar
@media screen and (max-width: 1190px) {
    .view-personnel {
        .view-personnel-form {
            .row {
                .full-size {
                    grid-column: 2;
                    grid-row: 1 / span 3;
                }
            }
        }
    }
}
@media screen and (max-width: 710px) {
    .view-personnel {
        .view-personnel-form {
            .row {
                .full-size {
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }
            }
        }
    }
}