.sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header-logo {
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .sidebar-content {
        background-color: $background;
        flex: 1;
        overflow-y: scroll;

        .sidebar-menus {
            height: 100%;
            display: flex;
            flex-direction: column;
            .sidebar-menu-session {
                padding: 8px;
                display: flex;
                flex-direction: column;
                
                .main-menu {
                    display: flex;
                    padding: 10px 12px;
                    gap: 10px;
                    background-color: $white-color;
                    cursor: pointer;
                    .menu-icon {

                    }
                    .menu-label {
                        flex: 1;
                    }
                    .menu-dropdown-icon {
                        transition: transform .3s;
                    }
                    &.active {
                        color: $primary-color;
                        font-weight: 500;

                        .menu-dropdown-icon {
                            transform: rotate(180deg);
                        }
                    }
                }
                .menu-dropdown {
                    padding: 10px 10px 10px 25px;
                    display: none;

                    .sub-menu {
                        display: flex;
                        padding: 5px 0px;
                        cursor: pointer;
                        font-weight: 400;
                        color: $text-color;
                        font-size: 15px;

                        &.active {
                            color: $primary-color;
                            font-weight: 500;
                        }
                        &:hover {
                            color: $primary-color;
                        }
                    }
                    &.active {
                        display: block;
                    }
                }
                &.active {
                    height: auto;
                }

                .tab-menu {
                    display: none;
                    padding: 5px 15px;
                    cursor: pointer;

                    &.active {
                        background-color: $white-color;
                    }
                    .menus-list {
                        display: none;
                        position: absolute;
                        background-color: $white-color;
                        border-radius: 8px;
                        box-shadow: 2px 4px 10px $shadow-color;
                        padding: 15px;
                        z-index: 9;

                        .menu-label {
                            font-size: 15px;
                            color: $primary-color;
                            padding-bottom: 10px;
                        }

                        .sub-menu {
                            padding: 5px 0px;
                            cursor: pointer;
    
                            &.active {
                                color: $primary-color;
                            }
                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                    &:hover .menus-list {
                        display: block;
                    }
                }
            }
        }
    }
}



// Responsive style in sidebar
@media screen and (max-width: 1490px) {
    .sidebar-container {
        .header-logo {
            height: 46px;
            .image {
                display: none;
            }
        }
        .sidebar-content {
            .sidebar-menus {
                .sidebar-menu-session {
                    .main-menu {
                        display: none;
                    }
                    .menu-dropdown.active {
                        display: none;
                    }
                    .tab-menu {
                        display: block;
                    }
                }
            }
        }
    }
}