.remote-opening-modal {
  background-color: $white-color;
  padding: 10px;
  border-radius: $box-border-radius;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .target-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      font-size: 16px;
      font-weight: 600;
      color: $primary-color; // Blue color for "Target"
      margin-right: 5px;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: $black-color; // Black/dark color for "All Doors"
    }
  }

  .input-section {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 20px;
    // width: 50%;

    .seconds {
      margin-top: 15px;
    }
  }
}