.camera-modal {
    padding: 20px;

    .camera-p {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p {
        font-size: 16px;
        color: $text-color;
    }

    .form-buttons-right {
        padding-top: 30px;
        justify-content: center;
    }

    .camera-modal-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .camera-modal-button {
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;

            &.camera-modal-cancel {
                background-color: $white-color;
                border: 1px solid $solid-color;
                color: $text-color;
            }

            &.camera-modal-confirm {
                background-color: $primary-color;
                color: $white-color;
                border: none;
            }

            &:hover {
                background-color: darken($primary-color, 10%);
            }
        }
    }
}
