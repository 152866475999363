.top-header {
    padding: 10px 5px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    .header-title {
        font-size: 19px;
        font-weight: 500;
    }
    .header-broad-cram {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .title {
            font-size: 14px;
        }
        .icon {

        }
        .page {
            font-size: 14px;
        }
    }
}