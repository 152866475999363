.multi-person {
  width: 650px;
  padding: 20px 10px 20px 10px;

    .forms-row {
        width: 80%;
    }
    .group-select-boxes {
      display: grid;
      gap: 1rem 2rem;
      margin-bottom: 1.5rem;
  
      .group {
        display: flex;
        gap: 1.5rem;
        height: 45px;
        .form-select-item .input_field:disabled, .form-select-item .input_field:disabled {
            background-color: $input-disabled;
        }
        
        span {
          font-weight: 500;
          color: $shade-color;
          white-space: nowrap;
        }
  
        .select-box {
          width: 100%;
        }
      }
    }
    .select-device-wrapper{
      padding: 20px 0 20px 0;
    .device-name {
      text-align: center;
      border-radius: 8px;
      border: 1px solid $text-disabled;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      width: 120px;
      height: 40px;
      padding: 14px 15px;

      .label {
          font-weight: 400;
      }
  }
    }

  }
  