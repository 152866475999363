.multiple-file-upload {
    width: 100%;

    .file-upload-section {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .file-label {
            span {
                color: $danger-color;
            }
        }
        .file-upload-box {
            height: 100px;
            border: solid $border-color 1px;
            border-radius: 8px;
            display: flex;
            .file-label {
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;
                cursor: pointer;
                font-weight: 600;
                font-size: 15px;
                letter-spacing: 1.4px;

                input {
                    display: none;
                }
            }

            .files-list {
                display: flex;
                flex-direction: row;
                gap: 10px;
                padding: 10px;
                position: absolute;

                .file-items {
                    font-size: 12px;
                    background-color: $background-color;
                    padding: 8px;
                    border-radius: 30px;

                    display: flex;
                    gap: 10px;

                    .file-name {
                        width: 70px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .icon {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}